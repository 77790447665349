import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import ServiceHeroSmall from "../../components/service-hero-small";
import ServiceCard from "../../components/service-card";
import Faq from "../../components/faq";
import Reviews from "../../components/reviews";
import CtaBanner from "../../components/cta-banner";
import Navigation from "../../components/navigation";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import SVGZoomDiscovery from "../../components/SVG/zoom-discovery-svg";
import SVGIdentify from "../../components/SVG/identify-svg";
import SVGContentCreated from "../../components/SVG/content-created-svg";

const CopywritingPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Info-Panel-Image-Tall" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			socialImg: wpMediaItem(title: { eq: "Social-Media-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			websiteCopyImg: wpMediaItem(title: { eq: "Website-Copy-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blogWritingImg: wpMediaItem(title: { eq: "Blog-Writing-Image-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			advertisingImg: wpMediaItem(title: { eq: "Advertising-Copy" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			digitalImg: wpMediaItem(title: { eq: "Digital-Content" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			blog2Img: wpMediaItem(title: { eq: "Blog-Writing-Image-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);
	const faqs = [
		{
			question:
				"Why should I choose ARC Writing and Translation Services to write my copy?",
			answer:
				"Because having your copy written by a professional writer who knows how to strike the right tone for your business will make your business more successful by creating the right impression with your existing and potential customers, leaving you free to focus on what you do best: running your business",
		},
		{
			question:
				"How do I know ARC Writing and Translation Services will write the best copy for me?",
			answer:
				"Once you have approached ARC Writing and Translation Services to provide you with a quote a meeting will be set up, face-to-face, online – via Zoom or an equivalent platform – or over the phone, to discuss your requirements and get a deeper understanding of your business.",
		},
		{
			question:
				"How will ARC Writing and Translations Services know how my industry works and what copy works best for me business?",
			answer:
				"Once an initial meeting has been set up, ARC Writing and Translation Services will undertake the relevant industry-specific research to identify the correct tone and terminology for your business and find out who your target customers are so that the copy delivered is tailored for the right audience.",
		},
		{
			question: "How much will it cost?",
			answer:
				"All copywriting projects involve different amounts of research and writing time, so it’s not a case of one size fits all. Once you get in touch and outline your needs, a price structure can be determined.",
		},

		{
			question: "How long will it take to deliver the copy?",
			answer:
				"Delivery will depend on the scope of the project and will be discussed upon initial contact. Urgent needs can be accommodated and rush rates applied. The version delivered is never the first draft, so time is needed to write at least three drafts to arrive at the perfect final draft.",
		},

		{
			question:
				"Is it possible to request revisions of the work and, if so, how many?",
			answer:
				"Yes, absolutely. Up to 2 revisions will be provided as standard. Any further changes may be subject to an extra fee.",
		},
	];
	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const socialImg = data.socialImg?.localFile.childImageSharp.gatsbyImageData;
	const websiteCopyImg =
		data.websiteCopyImg?.localFile.childImageSharp.gatsbyImageData;

	const blogWritingImg =
		data.blogWritingImg?.localFile.childImageSharp.gatsbyImageData;

	const advertisingImg =
		data.advertisingImg?.localFile.childImageSharp.gatsbyImageData;

	const digitalImg = data.digitalImg?.localFile.childImageSharp.gatsbyImageData;
	const blog2Img = data.blog2Img?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Copywriting",
				item: {
					url: `${siteUrl}/services/copywriting`,
					id: `${siteUrl}/services/copywriting`,
				},
			},
		],
	};

	return (
		<Layout>
			<Navigation />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title="Copywriting"
				description="Are you struggling to generate leads and drive traffic to your website? Looking for a copywriter to boost your sales?"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/copywriting`,
					title: "Copywriting",
					description:
						"Are you struggling to generate leads and drive traffic to your website? Looking for a copywriter to boost your sales?",
					images: [
						{
							url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/copywriting-services-scaled-1.jpg`,
							width: 1621,
							height: 2560,
							alt: "Copywriting services in London",
						},
					],
				}}
			/>
			<ServiceHeroSmall
				secondButtonText="Download my portfolio"
				secondButtonLink="https://arc-writing.rjmdigital.net/wp-content/uploads/2023/01/Antoinette-F-2023-1.pdf"
				secondButton={true}
				imgWidth="15rem"
				image={heroImg}
				imgAlt={data.heroImg.altText}
				title="Copywriting"
				subtitle="Because good copy matters"
				heading="Looking for a copywriter to boost your sales?"
				text={
					<>
						<p>
							Are you struggling to generate leads and drive traffic to your
							website? Would you like to increase your sales and get your
							message across to the right audience, in the right voice using the
							right words? Then you’ve come to the right place.
						</p>
						<p>
							ARC Writing and Translation Services can help you to grow your
							business by creating engaging, persuasive copy and content that
							speaks directly to YOUR customers.
						</p>
					</>
				}
			/>

			<Container className="text-center">
				<h2 className="mb-5 underline fs-1 ">Copywriting services</h2>
				<Row>
					{/* <ServiceCard
            image={socialImg}
            imgAlt={data.socialImg.altText}
            title="Social media posts & profiles"
            text="Stay ahead of the game among your competitors with posts and articles written for you that generate engagement and cross-platform sharing. Free up more time to focus on other aspects of your business by outsourcing your social media to a professional copywriter."
          /> */}
					<ServiceCard
						image={websiteCopyImg}
						imgAlt={data.websiteCopyImg.altText}
						title="Website copy"
						text="Engaging copy that generates more sales by using the right words to persuade your customers to buy your products and/or services is essential to grow your business. Transform your website with original, persuasive copy by trusting an expert copywriter to keep your potential customers on-page long enough to secure a sale."
					/>
					<ServiceCard
						image={blogWritingImg}
						imgAlt={data.blogWritingImg.altText}
						title="Blog writing"
						text="Blog posts written that your ideal customers will want to read and that raise your profile online. The subject of your blog posts will be thoroughly researched before it is written to save you time. Share your blogs across all your social media platforms to engage with potential customers and increase sales."
					/>
					<ServiceCard
						image={advertisingImg}
						imgAlt={data.advertisingImg.altText}
						title="Advertising copy"
						text="Does your company use brochures, leaflets and catalogues to market its products and/or services? I can help you by writing copy that best promotes your products and services with the correct wording for your target audience and captivating language that draws the reader in and sells your product."
					/>
					<ServiceCard
						image={digitalImg}
						imgAlt={data.digitalImg.altText}
						title="Digital content"
						text="Whatever your copy requirements are to promote your business online, I can help you by crafting catchy taglines, straplines or by writing video scripts for use on various digital platforms."
					/>
					<ServiceCard
						image={blog2Img}
						imgAlt={data.blog2Img.altText}
						title="Business documents"
						text="Sales letters and/or company reports written that look professional and strike the right tone for your audience. Whether formal for external readers or more informal for an in-house audience, if this sounds like a service that would save you time, then please get in touch for a bespoke quote that meets your needs."
					/>
				</Row>
			</Container>
			<section className="position-relative mb-6  text-center">
				<div
					style={{ zIndex: "-3" }}
					className="position-absolute w-100 h-100 bg-light-background  "
				></div>
				<Container className="py-5">
					<Row>
						<Col>
							<h2 className="underline fs-1">My Approach</h2>
							<p className="fs-5 mb-5  fw-light">
								You get two free rounds of review before signing off the project
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="px-4" lg={4}>
							<SVGZoomDiscovery className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Zoom discovery</h3>
							<p className="mb-5 ">
								We will have a FREE 30-minute Zoom discovery meeting during
								which we will discuss your requirements.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGIdentify className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">
								Identify your ideal client
							</h3>
							<p className="mb-5 ">
								We will have another Zoom meeting to identify your ideal client,
								establish the correct tone of voice and discuss the keywords for
								your on-page SEO and I will find out all about your business.
							</p>
						</Col>
						<Col className="px-4" lg={4}>
							<SVGContentCreated className="mw-20 mb-3 h-25" />
							<h3 className="normal-font fw-bold">Content created</h3>
							<p className="mb-5 ">
								I will create content that perfectly suits your needs using
								engaging copy that resonates with your target audience and is
								packed full of YOUR personality.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<Reviews />
			<Faq bottomNoMargin faqs={faqs} />
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default CopywritingPage;
